<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1>Trivellato Internal Store</h1>
              <h3>Il negozio Online dedicato ai dipendenti di Trivellato</h3>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="title">
            <h2>Prodotti</h2>
          </div>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
              style="margin-bottom: 30px"
            >
              <filters
                :maxValue="maxPrice"
                @change="filters = $event"
              ></filters>
            </div>
            <div class="md-layout-item">
              <products-list
                :products="products"
                :filtersModel="filters"
              ></products-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsList from "./components/ProductsList.vue";
import fetchFromAPI from "../scripts/Fetch";
import Filters from "./components/Filters";

export default {
  name: "index",
  bodyClass: "index-page",
  props: {
    image: {
      type: String,
      default: require("../assets/img/mercedes.jpg"),
    },
  },
  data() {
    return {
      products: [],
      filters: {},
      maxPrice: 0,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
  methods: {
    maxFilterValue() {
      let max;
      if (typeof this.products !== "undefined" && this.products.length > 0) {
        max = Math.max.apply(
          Math,
          this.products.map((o) => {
            return o.price;
          })
        );
      } else {
        max = 0;
      }
      return max;
    },
  },
  components: {
    ProductsList,
    Filters,
  },
  created() {
    fetchFromAPI("/load", {}, (res) => {
      this.products = res.products;
      this.maxPrice = this.maxFilterValue();
    });
  },
};
</script>
