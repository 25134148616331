<template>
  <div class="wrapper">
    <div
      class="section page-header header-filter small-background-container large-background-container"
      :style="headerStyle"
    >
      <img src="../assets/img/background.jpg" />
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto"
            style="margin-top: 20vh"
          >
            <generic-card header-color="green" :error="error">
              <h4 slot="title" class="card-title">Login</h4>
              <md-field
                class="md-form-group"
                slot="inputs"
                style="margin-top: 30px"
              >
                <md-icon>person</md-icon>
                <label>Username...</label>
                <md-input id="userId" v-model="email" type="email"></md-input>
              </md-field>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input
                  id="passwordId"
                  v-model="password"
                  type="password"
                ></md-input>
              </md-field>
              <md-button
                slot="footer"
                class="md-simple md-success md-lg"
                @click="LogIn"
              >
                LOGIN
              </md-button>
            </generic-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericCard from "../components/cards/GenericCard";
import User from "../views/components/UserState";

export default {
  components: {
    GenericCard,
  },
  bodyClass: "login-page",
  data() {
    return {
      email: null,
      password: null,
      error: false,
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/background.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundColor: "black",
        zIndex: 2,
      };
    },
  },
  methods: {
    LogIn() {
      User.login(this.email, this.password, () => (this.error = true));
    },
  },
  mounted() {
    document.getElementById("userId").focus();
  },
};
</script>

<style lang="css">
@media all and (max-width: 900px) and (min-width: 100px) {
  .small-background-container img {
    opacity: 0;
    margin-top: 7vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    max-height: 85%;
    z-index: 3;
    display: none;
  }
}

@media all and (max-width: 6000px) and (min-width: 900px) {
  .large-background-container img {
    opacity: 0.2;
    margin-top: 7vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    max-height: 85%;
    width: 100%;
    z-index: 1;
  }
}
</style>
