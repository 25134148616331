let Store = {
  debug: true,
  state: {
    productsToBuy: []
  },
  addProduct(product) {
    this.state.productsToBuy.push(product);
  },
  removeProduct(index) {
    this.state.productsToBuy.splice(index, 1);
    return this.state.productsToBuy;
  },
  addProductQuantity(index) {
    this.state.productsToBuy[index].quantity = this.state.productsToBuy[index].quantity + 1;
    return this.state.productsToBuy;
  },
  removeProductQuantity(index) {
    this.state.productsToBuy[index].quantity = this.state.productsToBuy[index].quantity - 1;
    return this.state.productsToBuy;
  },
  isThisProductAdded(id) {
    if (this.state.productsToBuy.length == 0) {
      return false;
    } else {
      return this.state.productsToBuy.filter(x => x.id == id).length > 0;
    }
  }
};

export default Store;