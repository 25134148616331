import axios from "axios";
const endPoint = process.env.VUE_APP_END_POINT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const fetchFromAPI = (url, data, cb) => {
    axios.post(endPoint + url, data).then((response) => {
        return response.data;
    }).then((res) => {
        cb(res);
    }).catch((err) => {
        throw err;
    })
}

export default fetchFromAPI;