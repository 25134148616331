<template>
  <div class="wrapper" style="margin-top: 25px">
    <div class="md-layout md-alignment-center-space-around">
      <div
        class="md-layout-item md-medium-size-45 md-small-size-100 md-xsmall-size-100 small-devices-container"
        v-for="(product, index) in productsBasedOnFilters"
        :key="index"
      >
        <product-card
          :product="product"
          style="margin: 15px; margin-bottom: 65px"
        />
      </div>
      <div v-if="productsBasedOnFilters.length == 0">
        <h4 style="margin: 10px">
          Nessun prodotto trovato con i filtri selezionati
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "./ProductCard";

export default {
  props: {
    products: Array,
    filtersModel: Object,
  },
  components: {
    ProductCard,
  },
  computed: {
    productsBasedOnFilters() {
      let filteredProducts = [];
      let activeCategories = [];
      if (typeof this.filtersModel.categories !== "undefined") {
        this.filtersModel.categories.forEach((c) => {
          if (c.active) {
            activeCategories.push(
              c.name === "Collezione" ? "Modellino" : c.name
            );
          }
        });
      }

      if (typeof this.filtersModel.price !== "undefined") {
        this.products.forEach((p) => {
          //price
          if (
            p.price >= this.filtersModel.price[0] &&
            p.price <= this.filtersModel.price[1] &&
            activeCategories.indexOf(p.category) > -1
          ) {
            filteredProducts.push(p);
          }
        });
        return filteredProducts;
      } else {
        return this.products;
      }
    },
  },
};
</script>

<style scoped>
@media all and (max-width: 400px) and (min-width: 50px) {
  .small-devices-container {
    margin-right: 30px;
  }
}
</style>