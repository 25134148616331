<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
    </parallax>
    <div class="main main-raised small-devices-margin large-devices-margin">
      <div class="section" style="margin-bottom: 30%">
        <div class="container">
          <div style="margin: 20px">
            <div class="title">
              <h2>I miei Ordini</h2>
            </div>
            <div v-if="transactions.length > 0">
              <md-table>
                <md-table-row style="background: unset !important">
                  <md-table-head></md-table-head>
                  <md-table-head>Data</md-table-head>
                  <md-table-head>Prodotti</md-table-head>
                  <md-table-head>Importo</md-table-head>
                  <md-table-head></md-table-head>
                </md-table-row>
                <md-table-row
                  v-for="(item, index) in transactions"
                  :key="index"
                  style="background: unset !important"
                >
                  <md-table-cell>
                    <div :class="getIconColorClass(item.transaction.Status)">
                      <i class="md-icon md-icon-font md-theme-default">{{
                        getIcon(item.transaction.Status)
                      }}</i>
                      <md-tooltip md-direction="bottom">{{
                        getToolTip(item.transaction.Status)
                      }}</md-tooltip>
                    </div>
                  </md-table-cell>
                  <md-table-cell style="font-size: 15px"
                    ><b>{{
                      item.transaction.CreatedDate.toString().replace("T", " ")
                    }}</b></md-table-cell
                  >
                  <md-table-cell>{{
                    getProdotti(item.products.products)
                  }}</md-table-cell>
                  <md-table-cell>
                    <b style="font-size: 15px"
                      >€ {{ item.transaction.Importo }}</b
                    >
                  </md-table-cell>
                  <md-table-cell>
                    <carousel
                      :per-page="1"
                      style="max-width: 130px"
                      :centerMode="true"
                      :autoplay="true"
                      :autoplayTimeout="7000"
                    >
                      <slide
                        v-for="(image, index) in getImagesList(item)"
                        :key="index"
                      >
                        <img
                          :src="image"
                          class="small-devices-image large-devices-image"
                        />
                      </slide>
                    </carousel>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
            <div v-else class="card-description" style="margin-top: 40px">
              Non hai eseguito ancora nessun ordine in precedenza
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Store from "../views/components/StoreState.js";
import User from "../views/components/UserState";
import route from "../router";
import fetchFromAPI from "../scripts/Fetch";

export default {
  data() {
    return {
      transactions: Object,
    };
  },
  props: {
    image: {
      type: String,
      default: require("../assets/img/details-background.jpg"),
    },
  },
  methods: {
    getIconColorClass(status) {
      if (status === "OK") {
        return "icon-success icon";
      } else if (status === "NUOVA") {
        return "icon-warning icon";
      } else {
        return "icon-danger icon";
      }
    },
    getToolTip(status) {
      if (status === "OK") {
        return "OPERAZIONE COMPLETATA";
      } else if (status === "NUOVA") {
        return "IN SOSPESO";
      } else {
        return "OPERAZIONE ANNULLATA";
      }
    },
    getProdotti(prodotti) {
      let products = [];
      prodotti.forEach((p) => products.push(p.title));
      return products.join(", ");
    },
    getIcon(status) {
      if (status === "OK") {
        return "check";
      } else if (status === "NUOVA") {
        return "credit_card";
      } else {
        return "error_outline";
      }
    },
    getImagesList(item) {
      let images = [];
      item.products.products
        .filter((p) => p.productImages)
        .forEach((image) => {
          images.push(image.productImages[0]);
        });
      return images;
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        maxHeight: "370px",
      };
    },
  },
  components: {
    Carousel,
    Slide,
  },
  created() {
    fetchFromAPI(
      "/oldTransactions",
      { email: User.getEmail(), token: User.getToken() },
      (res) => {
        this.transactions = res.transactions;
      }
    );
  },
};
</script>

<style scoped>
@media all and (max-width: 1400px) and (min-width: 200px) {
  .small-devices-margin {
    margin-right: 5%;
    margin-left: 5%;
  }

  .small-devices-image {
    min-width: 105px;
    margin-left: 13%;
    margin-right: 13%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1400px) {
  .large-devices-margin {
    margin-right: 20%;
    margin-left: 20%;
  }

  .large-devices-image {
    min-width: 150px;
  }
}
</style>