<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
    </parallax>
    <div class="main main-raised small-devices-margin large-devices-margin">
      <div class="section" style="margin-bottom: 10%">
        <div class="container">
          <div
            class="card-title"
            style="
              display: inline;
              align-items: center;
              justify-content: center;
            "
          >
            <div class="md-layout">
              <div class="md-layout-item">
                <h1 style="display: inline; margin-right: 50px">
                  Pagamento NON effettuato
                </h1>
              </div>
              <div class="md-layout-item">
                <div class="svg-container" style="display: inline">
                  <svg
                    viewBox="0 0 87 87"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="max-width: 90px"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="Group-2" transform="translate(2.000000, 2.000000)">
                        <circle
                          id="Oval-2"
                          stroke="rgba(252, 191, 191, .5)"
                          stroke-width="4"
                          cx="41.5"
                          cy="41.5"
                          r="41.5"
                        ></circle>
                        <circle
                          class="ui-error circle"
                          stroke="#F74444"
                          stroke-width="4"
                          cx="41.5"
                          cy="41.5"
                          r="41.5"
                        ></circle>
                        <path
                          class="ui-error line1"
                          d="M22.244224,22 L60.4279902,60.1837662"
                          id="Line"
                          stroke="#F74444"
                          stroke-width="3"
                          stroke-linecap="square"
                        ></path>
                        <path
                          class="ui-error line2"
                          d="M60.755776,21 L23.244224,59.8443492"
                          id="Line"
                          stroke="#F74444"
                          stroke-width="3"
                          stroke-linecap="square"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout md-centered" style="margin-top: 40px">
            <md-table>
              <md-table-row style="background: unset !important">
                <md-table-head></md-table-head>
                <md-table-head>Prodotto</md-table-head>
                <md-table-head>Prezzo</md-table-head>
                <md-table-head>Quantità</md-table-head>
                <md-table-head>Totale</md-table-head>
              </md-table-row>
              <md-table-row
                v-for="(product, index) in products"
                :key="index"
                style="background: unset !important"
              >
                <md-table-cell
                  ><div
                    class="img-container"
                    style="max-width: 170px; min-width: 110px"
                  >
                    <img :src="product.m_Item1.productImages[0]" /></div
                ></md-table-cell>
                <md-table-cell style="color: purple; font-size: 17px"
                  ><b>{{ product.m_Item1.title }}</b></md-table-cell
                >
                <md-table-cell>€{{ product.m_Item1.price }}</md-table-cell>
                <md-table-cell>
                  <div style="min-width: 190px">
                    <div
                      style="
                        display: inline;
                        font-size: 20px;
                        margin-right: 6px;
                      "
                    >
                      {{ product.m_Item2 }}
                    </div>
                  </div>
                </md-table-cell>
                <md-table-cell style="font-size: 17px"
                  ><b
                    >€{{ product.m_Item1.price * product.m_Item2 }}</b
                  ></md-table-cell
                >
              </md-table-row>
            </md-table>
          </div>
          <div class="card-description" style="margin-top: 25px">
            Il pagamento non è andato a buon fine. Riprova ad effettuare
            l'ordine in un secondo momento. Se il problema persiste, contatta
            l'ufficio IT.
          </div>
          <div class="md-layout" style="width: 100%">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <div class="info text-center">
                <div class="icon-info icon">
                  <i class="md-icon md-icon-font md-theme-default"
                    >local_shipping</i
                  >
                </div>
                <h4 class="info-title">Mettiti d'accordo per la consegna</h4>
                <p>
                  Verrai contattato/a per decidere dove effettuare la consegna
                  dei tuoi prodotti
                </p>
              </div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <div class="info text-center">
                <div class="icon-success icon">
                  <i class="md-icon md-icon-font md-theme-default"
                    >verified_user</i
                  >
                </div>
                <h4 class="info-title">Prodotti Verificati</h4>
                <p>
                  Prodotti che funzionano come nuovi, ma ad un prezzo più
                  accessibile
                </p>
              </div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <div class="info text-center">
                <div class="icon-rose icon">
                  <i class="md-icon md-icon-font md-theme-default">build</i>
                </div>
                <h4 class="info-title">Assistenza</h4>
                <p>
                  Non è inclusa assistenza tecnica da parte dell'ufficio IT una
                  volta completato l'ordine
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import route from "../router";
import fetchFromAPI from "../scripts/Fetch";

export default {
  data() {
    return {
      products: Object,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${require("../assets/img/index-bg.jpg")})`,
        maxHeight: "270px",
      };
    },
  },
  created() {
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const codTrans = urlParams.get("codTrans");
    const esito = urlParams.get("esito");
    let transactionId = this.$route.params.transactionId;
    fetchFromAPI(
      "/transactionFailed",
      {
        transactionId: transactionId,
        codTrans: codTrans,
        esito: esito,
      },
      (res) => {
        this.products = res.products;
      }
    );
  },
};
</script>

<style scoped>
@media all and (max-width: 1550px) and (min-width: 200px) {
  .small-devices-margin {
    margin-right: 5%;
    margin-left: 5%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1550px) {
  .large-devices-margin {
    margin-right: 15%;
    margin-left: 15%;
  }
}

@media all and (max-width: 1200px) and (min-width: 200px) {
  .small-devices-total {
    margin-left: 25%;
    margin-right: 5%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1200px) {
  .large-devices-total {
    margin-left: 80%;
  }
}

@supports (animation: grow 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
  }

  .circle {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
  }
}

@keyframes draw {
  0%,
  100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.demo1 {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-success,
.ui-error {
  width: 100px;
  height: 100px;
  margin: 40px;
}

@keyframes ani-success-circle {
  to {
    stroke-dashoffset: 782.2565707438586px;
  }
}

@keyframes ani-success-path {
  0% {
    stroke-dashoffset: 62px;
  }
  65% {
    stroke-dashoffset: -5px;
  }
  84% {
    stroke-dashoffset: 4px;
  }
  100% {
    stroke-dashoffset: -2px;
  }
}

.circle {
  stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
  stroke-dashoffset: 260.75219024795285px;
  animation: ani-error-circle 1.2s linear;
}
.line1 {
  stroke-dasharray: 54px 55px;
  stroke-dashoffset: 55px;
  stroke-linecap: round;
  animation: ani-error-line 0.15s 1.2s linear both;
}
.line2 {
  stroke-dasharray: 54px 55px;
  stroke-dashoffset: 55px;
  stroke-linecap: round;
  animation: ani-error-line 0.2s 0.9s linear both;
}

@keyframes ani-error-line {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: -260.75219024795285px;
  }
  100% {
    stroke-dasharray: 260.75219024795285px, 0;
    stroke-dashoffset: -260.75219024795285px;
  }
}
</style>