<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-transparent md-absolute"
    :class="extraNavClasses"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title" style="font-size: 22px; font-weight: 500">
          Trivellato Store
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile"
        >
          <md-badge
            :md-content="counter.productsToBuy.length"
            md-dense
            v-if="isLogged && counter.productsToBuy.length > 0"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-badge>
          <div v-else>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </div>
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section-start="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <md-list-item
                href="#/"
                @click="toggleNavbarMobile"
                v-if="homeButton"
              >
                <i class="fas fa-home" style="font-size: 23px"></i>
                <p class="hidden-lg">Home</p>
              </md-list-item>
              <md-badge
                :md-content="counter.productsToBuy.length"
                md-dense
                v-if="
                  isLogged &&
                  counter.productsToBuy.length > 0 &&
                  !NavbarStore.showNavbar
                "
              >
                <md-list-item href="#/shopping-cart">
                  <i class="fas fa-shopping-cart" style="font-size: 23px"></i>
                  <p class="hidden-lg">Carrello</p>
                </md-list-item>
              </md-badge>

              <md-list-item
                href="#/shopping-cart"
                @click="toggleNavbarMobile"
                v-if="
                  isLogged &&
                  NavbarStore.showNavbar &&
                  counter.productsToBuy.length > 0
                "
              >
                <i class="fas fa-shopping-cart" style="font-size: 23px"></i>
                <p class="hidden-lg">Carrello</p>
                <md-tooltip md-direction="bottom">Carrello</md-tooltip>
                <md-badge
                  :md-content="counter.productsToBuy.length"
                  style="margin-left: 5px"
                />
              </md-list-item>
              <md-list-item
                href="#/shopping-cart"
                @click="toggleNavbarMobile"
                v-if="isLogged && counter.productsToBuy.length == 0"
              >
                <i class="fas fa-shopping-cart" style="font-size: 23px"></i>
                <p class="hidden-lg">Carrello</p>
                <md-tooltip md-direction="bottom">Carrello</md-tooltip>
              </md-list-item>
              <md-list-item
                href="#/orders-history"
                @click="toggleNavbarMobile"
                v-if="isLogged"
              >
                <i class="fas fa-history" style="font-size: 23px"></i>
                <p class="hidden-lg">Storico Ordini</p>
                <md-tooltip md-direction="bottom">Storico Ordini</md-tooltip>
              </md-list-item>
              <md-list-item
                href="https://twitter.com/trivellatoauto"
                target="_blank"
                v-if="!isLogged"
              >
                <i class="fab fa-twitter"></i>
                <p class="hidden-lg">Twitter</p>
              </md-list-item>
              <md-list-item
                href="https://www.facebook.com/TrivellatoGruppo/"
                target="_blank"
                v-if="!isLogged"
              >
                <i class="fab fa-facebook-square"></i>
                <p class="hidden-lg">Facebook</p>
              </md-list-item>
              <md-list-item
                href="https://www.instagram.com/trivellato/?hl=it"
                target="_blank"
                v-if="!isLogged"
              >
                <i class="fab fa-instagram"></i>
                <p class="hidden-lg">Instagram</p>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
import Store from "../views/components/StoreState.js";
import router from "../router";

export default {
  components: {
    MobileMenu,
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
        ].includes(value);
      },
    },
    homeButton: {
      type: Boolean,
      default: true,
    },
    boldeness: {
      type: Number,
      default: 400,
    },
    isLogged: {
      type: Boolean,
      default: false,
    },
    colorOnScroll: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
      counter: Store.state,
    };
  },
  computed: {
    showDownload() {
      const excludedRoutes = ["login", "landing", "profile"];
      return excludedRoutes.every((r) => r !== this.$route.name);
    },
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    toggleNavbarMobileDesktop() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
    },
    redirectToHome() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      router.push("/");
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement() {
      let element_id = document.getElementById("downloadSection");
      if (element_id) {
        element_id.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
