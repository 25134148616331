var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-toolbar',{staticClass:"md-transparent md-absolute",class:_vm.extraNavClasses,attrs:{"id":"toolbar","md-elevation":"0","color-on-scroll":_vm.colorOnScroll}},[_c('div',{staticClass:"md-toolbar-row md-collapse-lateral"},[_c('div',{staticClass:"md-toolbar-section-start"},[_c('h3',{staticClass:"md-title",staticStyle:{"font-size":"22px","font-weight":"500"}},[_vm._v(" Trivellato Store ")])]),_c('div',{staticClass:"md-toolbar-section-end"},[_c('md-button',{staticClass:"md-just-icon md-simple md-toolbar-toggle",class:{ toggled: _vm.toggledClass },on:{"click":_vm.toggleNavbarMobile}},[(_vm.isLogged && _vm.counter.productsToBuy.length > 0)?_c('md-badge',{attrs:{"md-content":_vm.counter.productsToBuy.length,"md-dense":""}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})]):_c('div',[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})])],1),_c('div',{staticClass:"md-collapse"},[_c('div',{staticClass:"md-collapse-wrapper"},[_c('mobile-menu',{attrs:{"nav-mobile-section-start":"false"}}),_c('md-list',[(_vm.homeButton)?_c('md-list-item',{attrs:{"href":"#/"},on:{"click":_vm.toggleNavbarMobile}},[_c('i',{staticClass:"fas fa-home",staticStyle:{"font-size":"23px"}}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Home")])]):_vm._e(),(
                _vm.isLogged &&
                _vm.counter.productsToBuy.length > 0 &&
                !_vm.NavbarStore.showNavbar
              )?_c('md-badge',{attrs:{"md-content":_vm.counter.productsToBuy.length,"md-dense":""}},[_c('md-list-item',{attrs:{"href":"#/shopping-cart"}},[_c('i',{staticClass:"fas fa-shopping-cart",staticStyle:{"font-size":"23px"}}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Carrello")])])],1):_vm._e(),(
                _vm.isLogged &&
                _vm.NavbarStore.showNavbar &&
                _vm.counter.productsToBuy.length > 0
              )?_c('md-list-item',{attrs:{"href":"#/shopping-cart"},on:{"click":_vm.toggleNavbarMobile}},[_c('i',{staticClass:"fas fa-shopping-cart",staticStyle:{"font-size":"23px"}}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Carrello")]),_c('md-tooltip',{attrs:{"md-direction":"bottom"}},[_vm._v("Carrello")]),_c('md-badge',{staticStyle:{"margin-left":"5px"},attrs:{"md-content":_vm.counter.productsToBuy.length}})],1):_vm._e(),(_vm.isLogged && _vm.counter.productsToBuy.length == 0)?_c('md-list-item',{attrs:{"href":"#/shopping-cart"},on:{"click":_vm.toggleNavbarMobile}},[_c('i',{staticClass:"fas fa-shopping-cart",staticStyle:{"font-size":"23px"}}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Carrello")]),_c('md-tooltip',{attrs:{"md-direction":"bottom"}},[_vm._v("Carrello")])],1):_vm._e(),(_vm.isLogged)?_c('md-list-item',{attrs:{"href":"#/orders-history"},on:{"click":_vm.toggleNavbarMobile}},[_c('i',{staticClass:"fas fa-history",staticStyle:{"font-size":"23px"}}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Storico Ordini")]),_c('md-tooltip',{attrs:{"md-direction":"bottom"}},[_vm._v("Storico Ordini")])],1):_vm._e(),(!_vm.isLogged)?_c('md-list-item',{attrs:{"href":"https://twitter.com/trivellatoauto","target":"_blank"}},[_c('i',{staticClass:"fab fa-twitter"}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Twitter")])]):_vm._e(),(!_vm.isLogged)?_c('md-list-item',{attrs:{"href":"https://www.facebook.com/TrivellatoGruppo/","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook-square"}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Facebook")])]):_vm._e(),(!_vm.isLogged)?_c('md-list-item',{attrs:{"href":"https://www.instagram.com/trivellato/?hl=it","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"}),_c('p',{staticClass:"hidden-lg"},[_vm._v("Instagram")])]):_vm._e()],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }