<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header
      :class="getClass(headerColor)"
      style="
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 240px;
      "
      :style="imageStyle"
    >
      <md-card-media>
        <slot name="image"></slot>
      </md-card-media>
      <slot name="title"></slot>
      <div class="social-line">
        <slot name="buttons"></slot>
      </div>
    </md-card-header>

    <md-card-content style="min-height: 230px">
      <slot name="description"></slot>
      <slot name="inputs"></slot>
    </md-card-content>

    <md-card-actions>
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: "login-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
    image: String,
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
  methods: {
    showCard: function () {
      this.cardHidden = false;
    },
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + " header-animation";
    },
  },
};
</script>

<style lang="css">
.header-animation {
  transition: all 0.2s ease-out;
}

.header-animation:hover {
  transform: translate(0, -7px);
}
</style>
