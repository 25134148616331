import Vue from "vue";
import Router from "vue-router";
import Index from "./views/Index.vue";
import Login from "./views/Login.vue";
import Details from './views/Details.vue';
import ShoppingCart from './views/ShoppingCart.vue';
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import User from './views/components/UserState';
import TransactionSuccess from './views/TransactionSuccess.vue';
import TransactionFailed from './views/TransactionFailed.vue';
import History from './views/History.vue';
import Intranet from './views/Intranet.vue';
import LoginIntranet from './views/LoginIntranet.vue';
import NotFound from './views/NotFount.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: {
          colorOnScroll: 400,
          isLogged: true,
          homeButton: false
        }
      },
      beforeEnter: (to, from, next) => {
        if (User.status()) {
          next();
        } else {
          next('/login');
        }
      }
    },
    {
      path: "/login",
      name: "login",
      components: { default: Login, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false, homeButton: false  },
        footer: { backgroundColor: "black"}
      }
    },
    {
      path: "/loginIntranet",
      name: "loginIntranet",
      components: { default: LoginIntranet, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false, homeButton: false  },
        footer: { backgroundColor: "black"}
      }
    },
    {
      path: "/details/:id",
      name: "details",
      components: { default: Details, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: true },
      },
      beforeEnter: (to, from, next) => {
        if (User.status()) {
          next();
        } else {
          next('/login');
        }
      }
    },
    {
      path: "/shopping-cart",
      name: "cart",
      components: { default: ShoppingCart, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false },
      },
      beforeEnter: (to, from, next) => {
        if (User.status()) {
          next();
        } else {
          next('/login');
        }
      }
    },
    {
      path: "/orders-history",
      name: "history",
      components: { default: History, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false },
      },
      beforeEnter: (to, from, next) => {
        if (User.status()) {
          next();
        } else {
          next('/login');
        }
      }
    },
    {
      path: "/intranet",
      name: "intranet",
      components: { default: Intranet, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false },
      },
      beforeEnter: (to, from, next) => {
        if (User.isAdmin()) {
          next();
        } else {
          next('/loginIntranet');
        }
      }
    },
    {
      path: "/transaction/completed/:transactionId",
      name: "success",
      components: { default: TransactionSuccess, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false },
      },
    },
    {
      path: "/transaction/failed/:transactionId",
      name: "failer",
      components: { default: TransactionFailed, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false },
      },
    },
    {
      path: '/:pathMatch(.*)',
      name: "NotFound",
      components: { default: NotFound, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, isLogged: false },
        footer: { backgroundColor: "#737373"}
      },
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
