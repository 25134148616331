<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :style="styleFooter"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https://www.trivellato.it/"> Trivellato </a>
          </li>
          <li>
            <a href="https://www.trivellato.it/pagina/il-gruppo"> About Us </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, designed and developed by Trivellato SpA
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: null,
    },
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    styleFooter() {
      if (this.backgroundColor) {
        return {
          backgroundColor: this.backgroundColor,
        };
      } else {
        return {};
      }
    },
  },
};
</script>
<style></style>
