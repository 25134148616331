<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
    </parallax>
    <div class="main main-raised small-devices-margin large-devices-margin">
      <div class="section" style="margin-bottom: 10%">
        <div class="container">
          <div class="md-layout md-alignment-center-space-around">
            <div
              class="md-item large-devices-container small-devices-container"
            >
              <carousel
                :per-page="1"
                style="max-width: 600px"
                :centerMode="true"
                :autoplay="true"
                :autoplayTimeout="7000"
              >
                <slide
                  v-for="(image, index) in product.productImages"
                  :key="index"
                >
                  <img
                    :src="image"
                    class="small-devices-image large-devices-image"
                  />
                </slide>
              </carousel>
            </div>
            <div class="md-item md-alignment-right">
              <div style="margin: 7%">
                <div class="card-title" style="font-size: 30px">
                  {{ product.title }}
                </div>
                <div
                  class="card-description"
                  style="font-size: 20px; font-weight: 400; margin-top: 30px"
                >
                  <i class="fas fa-tags" style="font-size: 16px"></i> €{{
                    priceFormatted
                  }}
                </div>
                <div
                  class="card-description"
                  style="max-width: 550px; margin-top: 30px"
                >
                  {{ product.description }}
                </div>
                <div
                  class="card-title"
                  style="max-width: 550px; margin-top: 30px; font-size: 14px"
                >
                  {{
                    product.maxQuantity == 0
                      ? "Articolo non più disponibile"
                      : product.maxQuantity == 1
                      ? "Solo 1 disponibile"
                      : `${product.maxQuantity} articoli disponibili`
                  }}
                </div>
                <div
                  v-if="
                    product.category === 'Accessorio' ||
                    product.category === 'Modellino'
                  "
                  class="card-description"
                >
                  <h5 style="font-size: 14px">
                    [Cod. {{ product.numeroCespite.split(",")[0] }}]
                  </h5>
                </div>
                <md-button
                  :class="buttonStyle"
                  style="margin-top: 50px"
                  @click="addProductToShoppingList"
                  ><i :class="buttonIcon" style="margin-right: 5px"></i>
                  {{ itemsAdded ? "Aggiunto" : "Aggiungi" }} al
                  carrello</md-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Store from "../views/components/StoreState.js";
import route from "../router";
import fetchFromAPI from "../scripts/Fetch";

export default {
  data() {
    return {
      sharedState: Store.state,
      product: Object,
      itemsAdded: false,
    };
  },
  props: {
    image: {
      type: String,
      default: require("../assets/img/details-background.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        maxHeight: "370px",
      };
    },
    priceFormatted() {
      return this.product.price
        ? this.product.price.toString().includes(".")
          ? this.product.price.toString().split(".")[1].length == 1
            ? this.product.price.toString().replace(".", ",") + "0"
            : this.product.price.toString().replace(".", ",")
          : `${this.product.price},00`
        : "0,00";
    },
    buttonStyle() {
      if (this.product.maxQuantity > 0) {
        if (!this.itemsAdded) {
          return "md-rose md-round";
        } else {
          return "md-success md-round disabled";
        }
      } else {
        return "md-rose md-round disabled";
      }
    },
    buttonIcon() {
      if (!this.itemsAdded) {
        return "fas fa-shopping-cart";
      } else {
        return "fas fa-check";
      }
    },
  },
  methods: {
    addProductToShoppingList() {
      this.product.quantity = 1;
      Store.addProduct(this.product);
      this.itemsAdded = true;
    },
  },
  components: {
    Carousel,
    Slide,
  },
  created() {
    let paramsId = this.$route.params.id;
    fetchFromAPI("/loadItem", { id: paramsId }, (res) => {
      this.product = res;
      this.itemsAdded = Store.isThisProductAdded(this.product.id);
    });
  },
};
</script>

<style scoped>
@media all and (max-width: 1400px) and (min-width: 200px) {
  .small-devices-margin {
    margin-right: 5%;
    margin-left: 5%;
  }

  .small-devices-image {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media all and (max-width: 800px) and (min-width: 100px) {
  .small-devices-container {
    margin: 3%;
    width: 100%;
  }
}
@media all and (max-width: 6000px) and (min-width: 800px) {
  .large-devices-container {
    margin: 3%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1400px) {
  .large-devices-margin {
    margin-right: 20%;
    margin-left: 20%;
  }

  .large-devices-image {
    min-width: 600px;
  }
}
</style>