<template>
  <login-card
    style="max-width: 320px; min-height: 500px; max-height: 550px"
    :image="product.productImages[0]"
  >
    <div slot="image" class="card-title"></div>
    <div class="md-form-group" slot="description" style="margin-top: 30px">
      <h4 class="card-title">{{ product.title }}</h4>
      <p class="card-description" style="min-height: 90px">
        {{
          product.description.length >= 100
            ? product.description.substring(0, 100) + "..."
            : product.description
        }}
      </p>
      <br />
      <span style="font-size: 25px"
        ><b>€{{ priceFormatted }}</b></span
      >
    </div>
    <div slot="footer">
      <div class="md-layout md-alignment-bottom-center">
        <div class="md-layout-item md-size-25">
          <md-button class="md-info" @click="redirectToDetails(product.id)"
            ><md-icon>info</md-icon>Dettagli Prodotto</md-button
          >
        </div>
      </div>
    </div>
  </login-card>
</template>

<script>
import LoginCard from "../../components/cards/LoginCard";
import router from "../../router";

export default {
  props: {
    product: Object,
  },
  methods: {
    redirectToDetails(id) {
      router.push({ path: `/details/${id}` });
    },
  },
  computed: {
    priceFormatted() {
      return this.product.price.toString().includes(".")
        ? this.product.price.toString().split(".")[1].length == 1
          ? this.product.price.toString().replace(".", ",") + "0"
          : this.product.price.toString().replace(".", ",")
        : `${this.product.price},00`;
    },
  },
  components: {
    LoginCard,
  },
};
</script>