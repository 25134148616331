<template>
  <div class="wrapper">
    <div
      class="section page-header header-filter background-container"
      :style="headerStyle"
    >
      <img src="../assets/img/background.jpg" />
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto"
            style="margin-top: 20vh"
          >
            <generic-card header-color="green">
              <h4 slot="title" class="card-title">Login Intranet</h4>
              <md-field
                class="md-form-group"
                slot="inputs"
                style="margin-top: 30px"
              >
                <md-icon>person</md-icon>
                <label>Username...</label>
                <md-input v-model="email" type="email"></md-input>
              </md-field>
              <md-field class="md-form-group" slot="inputs">
                <md-icon>lock_outline</md-icon>
                <label>Password...</label>
                <md-input v-model="password" type="password"></md-input>
              </md-field>
              <md-button
                slot="footer"
                class="md-simple md-success md-lg"
                @click="LogIn"
              >
                LOGIN
              </md-button>
            </generic-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericCard from "../components/cards/GenericCard";
import User from "../views/components/UserState";

export default {
  components: {
    GenericCard,
  },
  bodyClass: "login-page",
  data() {
    return {
      email: null,
      password: null,
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/background.jpg"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundColor: "black",
        zIndex: 2,
      };
    },
  },
  methods: {
    LogIn() {
      User.loginIntranet(this.email, this.password);
    },
  },
};
</script>

<style lang="css">
.background-container img {
  opacity: 0.3;
  margin-top: 7vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
