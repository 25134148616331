import fetchFromAPI from '../../scripts/Fetch';
import router from '../../router';

let User = {
  debug: true,
  state: {
    email: "",
    isLoggedIn: false,
    isSa: false,
    token: ""
  },
  status() {
    return this.state.isLoggedIn;
  },
  isAdmin() {
    return this.state.isSa;
  },
  getEmail() {
    return this.state.email;
  },
  getToken() {
    return this.state.token;
  },
  login(username, password, cb) {
    fetchFromAPI('/login', { email: username, password: password }, (res) => {
      this.state.email = res.email;
      this.state.isLoggedIn = res.logged;
      this.state.token = res.token;
      if (res.logged) {
        router.push("/");
      } else {
        cb();
      }
    });
  },
  loginIntranet(username, password) {
    fetchFromAPI('/loginIntranet', { email: username, password: password }, (res) => {
      this.state.email = res.email;
      this.state.isLoggedIn = res.logged;
      this.state.token = res.token;
      this.state.isSa = res.logged;
      if (res.logged) {
        router.push("/intranet");
      }
    });
  }
};

export default User;