<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand" style="color: white">
              <h1 style="font-weight: 500">Carrello</h1>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised small-devices-margin large-devices-margin">
      <div class="section" style="margin-bottom: 10%">
        <div class="container">
          <modal v-if="modal" @close="modal = false">
            <template slot="header">
              <h4 class="modal-title">Errore!</h4>
              <md-button
                class="md-simple md-just-icon md-round modal-default-button"
                @click="classicModalHide"
              >
                <md-icon>clear</md-icon>
              </md-button>
            </template>

            <template slot="body">
              <p>
                {{ message }}
              </p>
            </template>

            <template slot="footer">
              <md-button class="md-danger md-simple" @click="modal = false"
                >Chiudi</md-button
              >
            </template>
          </modal>
          <div class="card-title" style="font-size: 25px">
            Prodotti nel carrello
          </div>
          <div v-if="products.length == 0" style="margin-top: 50px">
            <h4>Non hai ancora aggiunto nessun prodotto al carrello</h4>
          </div>
          <div v-else style="margin-top: 50px">
            <div v-if="!mobileMode">
              <md-table>
                <md-table-row style="background: unset !important">
                  <md-table-head></md-table-head>
                  <md-table-head>Prodotto</md-table-head>
                  <md-table-head>Prezzo</md-table-head>
                  <md-table-head>Quantità</md-table-head>
                  <md-table-head>Totale</md-table-head>
                  <md-table-head></md-table-head>
                </md-table-row>
                <md-table-row
                  v-for="(product, index) in products"
                  :key="index"
                  style="background: unset !important"
                >
                  <md-table-cell
                    ><div
                      class="img-container"
                      style="max-width: 170px; min-width: 110px"
                    >
                      <img :src="product.productImages[0]" /></div
                  ></md-table-cell>
                  <md-table-cell style="color: purple; font-size: 17px"
                    ><b>{{ product.title }}</b></md-table-cell
                  >
                  <md-table-cell>€{{ product.price }}</md-table-cell>
                  <md-table-cell>
                    <div style="min-width: 190px">
                      <div
                        style="
                          display: inline;
                          font-size: 20px;
                          margin-right: 6px;
                        "
                      >
                        {{ product.quantity }}
                      </div>
                      <div class="md-group">
                        <md-button
                          @click="addQuantity(index)"
                          class="md-button md-round md-info md-sm md-theme-default"
                          ><i class="md-icon md-icon-font md-theme-default"
                            >add</i
                          ></md-button
                        >
                        <md-button
                          @click="removeQuantity(index)"
                          class="md-button md-round md-info md-sm md-theme-default"
                          ><i class="md-icon md-icon-font md-theme-default"
                            >remove</i
                          ></md-button
                        >
                      </div>
                      <md-badge
                        style="
                          font-size: 12px;
                          margin-right: 15%;
                          min-width: 200px;
                        "
                        class="md-square"
                        v-if="maxAlert && index == currentIndex"
                        md-content="Numero di artitoli massimi raggiunti!"
                      ></md-badge>
                    </div>
                  </md-table-cell>
                  <md-table-cell style="font-size: 17px"
                    ><b
                      >€{{ product.price * product.quantity }}</b
                    ></md-table-cell
                  >
                  <md-table-cell
                    ><md-button
                      @click="removeProduct(index)"
                      class="md-button md-just-icon md-round md-simple md-theme-default"
                      ><i class="md-icon md-icon-font md-theme-default"
                        >close</i
                      ></md-button
                    ></md-table-cell
                  >
                </md-table-row>
              </md-table>
            </div>
            <div v-else>
              <div
                class="md-layout"
                v-for="(product, index) in products"
                :key="index"
              >
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <div
                    class="img-container"
                    style="max-width: 170px; min-width: 110px"
                  >
                    <img :src="product.productImages[0]" />
                  </div>
                  <div class="card-title">
                    {{ product.title }}
                  </div>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-70">
                  <h5>Prezzo: €{{ product.price }}</h5>
                  <h5 style="display: inline">
                    Quantità: {{ product.quantity }}
                  </h5>
                  <div class="md-group">
                    <md-button
                      @click="addQuantity(index)"
                      class="md-button md-round md-info md-sm md-theme-default"
                      ><i class="md-icon md-icon-font md-theme-default"
                        >add</i
                      ></md-button
                    >
                    <md-button
                      @click="removeQuantity(index)"
                      class="md-button md-round md-info md-sm md-theme-default"
                      ><i class="md-icon md-icon-font md-theme-default"
                        >remove</i
                      ></md-button
                    >
                  </div>
                  <md-badge
                    style="font-size: 12px; margin-right: 15%; min-width: 200px"
                    class="md-square"
                    v-if="maxAlert && index == currentIndex"
                    md-content="Numero di artitoli massimi raggiunti!"
                  ></md-badge>
                  <h5>
                    Totale: <b>€{{ product.price * product.quantity }}</b>
                  </h5>
                </div>
                <div class="md-layout-item md-small-size-30">
                  <md-button
                    @click="removeProduct(index)"
                    class="md-button md-just-icon md-round md-simple md-theme-default"
                    ><i class="md-icon md-icon-font md-theme-default"
                      >delete</i
                    ></md-button
                  >
                </div>
              </div>
            </div>
            <div
              style="min-width: 160px; font-size: 18px; margin-top: 25px"
              class="small-devices-total large-devices-total"
            >
              <div
                class="card-title"
                style="display: inline; margin-left: 30px"
              >
                Totale
              </div>
              <div
                class="card-description"
                style="
                  display: inline;
                  font-size: 23px;
                  font-weight: 400;
                  margin-left: 15px;
                "
              >
                <span style="font-size: 19px; margin-left: 8px">€</span>
                {{ totalImport() }}
              </div>
            </div>
            <div
              class="small-devices-total large-devices-total"
              style="margin-top: 15px"
            >
              <form id="frm" name="actionForm" action="" method="post"></form>
              <md-button class="md-info md-round" @click="createCheckout"
                ><i class="far fa-credit-card" style="margin-right: 4px"></i>
                Procedi al Pagamento</md-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="md-layout"
      style="margin-top: -45px; margin-bottom: 40p; width: 100%"
    >
      <div class="md-layout-item md-size-33 md-small-size-100">
        <div class="info text-center">
          <div class="icon-info icon">
            <i class="md-icon md-icon-font md-theme-default">local_shipping</i>
          </div>
          <h4 class="info-title">Mettiti d'accordo per la consegna</h4>
          <p>
            Verrai contattato/a per decidere dove effettuare la consegna dei
            tuoi prodotti
          </p>
        </div>
      </div>
      <div class="md-layout-item md-size-33 md-small-size-100">
        <div class="info text-center">
          <div class="icon-success icon">
            <i class="md-icon md-icon-font md-theme-default">verified_user</i>
          </div>
          <h4 class="info-title">Prodotti Verificati</h4>
          <p>
            Prodotti che funzionano come nuovi, ma ad un prezzo più accessibile
          </p>
        </div>
      </div>
      <div class="md-layout-item md-size-33 md-small-size-100">
        <div class="info text-center">
          <div class="icon-rose icon">
            <i class="md-icon md-icon-font md-theme-default">build</i>
          </div>
          <h4 class="info-title">Assistenza</h4>
          <p>
            Non è inclusa assistenza tecnica da parte dell'ufficio IT una volta
            completato l'ordine
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Store from "../views/components/StoreState.js";
import route from "../router";
import fetchFromAPI from "../scripts/Fetch.js";
import User from "../views/components/UserState";
import { Modal } from "@/components";

export default {
  data() {
    return {
      products: Store.state.productsToBuy,
      maxAlert: false,
      currentIndex: 0,
      modal: false,
      message: "",
      mobileMode: false,
    };
  },
  components: {
    Modal,
  },
  props: {
    image: {
      type: String,
      default: require("../assets/img/shopping.jpeg"),
    },
  },
  methods: {
    myEventHandler(e) {
      if (e.currentTarget.outerWidth < 450) {
        this.mobileMode = true;
      } else {
        this.mobileMode = false;
      }
    },
    removeProduct(index) {
      this.products = Store.removeProduct(index);
    },
    addQuantity(index) {
      this.currentIndex = index;
      if (this.products[index].quantity < this.products[index].maxQuantity) {
        this.maxAlert = false;
        this.products = Store.addProductQuantity(index);
        this.$forceUpdate();
      } else {
        this.maxAlert = true;
        setTimeout(() => {
          this.maxAlert = false;
        }, 2000);
      }
    },
    createCheckout() {
      let productIds = [];
      this.products.forEach((p) => {
        let prod = {
          id: p.id.toString(),
          quantity: p.quantity.toString(),
        };
        productIds.push(prod);
      });
      fetchFromAPI(
        "/checkout",
        {
          importo: this.totalImport(),
          email: User.getEmail(),
          products: productIds,
          token: User.getToken(),
        },
        (res) => {
          if (res.Message === "Success") {
            history.replaceState(
              {
                urlPath:
                  res.UrlBack +
                  `?from=history&codTrans=${res.CodTrans}&esito=ANNULLO`,
              },
              ``,
              res.UrlBack +
                `?from=history&codTrans=${res.CodTrans}&esito=ANNULLO`
            );

            let form = document.forms["actionForm"];
            this.addHidden(form, "alias", res.Alias);
            this.addHidden(form, "importo", res.Importo);
            this.addHidden(form, "divisa", res.Divisa);
            this.addHidden(form, "codTrans", res.CodTrans);
            this.addHidden(form, "url", res.Url);
            this.addHidden(form, "url_back", res.UrlBack);
            this.addHidden(form, "urlpost", res.UrlPost);
            this.addHidden(form, "mac", res.Mac);
            this.addHidden(form, "mail", res.Mail);
            this.addHidden(form, "Articoli", res.articoli);
            form.action = res.requestUrl;
            form.submit();
          } else {
            this.message = res.Message;
            this.modal = true;
          }
        }
      );
    },
    addHidden(theForm, key, value) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = value;
      theForm.appendChild(input);
    },
    removeQuantity(index) {
      this.currentIndex = index;
      if (this.products[index].quantity > 1) {
        this.maxAlert = false;
        this.products = Store.removeProductQuantity(index);
        this.$forceUpdate();
      }
    },
    totalImport() {
      let totalImport = 0;
      this.products.forEach((p) => (totalImport += p.price * p.quantity));
      return totalImport.toFixed(2);
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        maxHeight: "600px",
      };
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.NavbarStore.showNavbar = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style scoped>
@media all and (max-width: 1550px) and (min-width: 200px) {
  .small-devices-margin {
    margin-right: 5%;
    margin-left: 5%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1550px) {
  .large-devices-margin {
    margin-right: 15%;
    margin-left: 15%;
  }
}

@media all and (max-width: 1200px) and (min-width: 200px) {
  .small-devices-total {
    margin-left: 25%;
    margin-right: 5%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1200px) {
  .large-devices-total {
    margin-left: 80%;
  }
}
</style>