<template>
  <div>
    <div
      class="accordion-item"
      v-for="(content, i) in contents"
      :class="{ 'accordion-active': content.active }"
      :key="i"
    >
      <div class="accordion-header">
        <a href="#" @click="expand($event, i)" class="custom-accordion-header">
          <div class="accordion-header-div custom-accordion-header">
            {{ content.title }}
          </div>
          <div class="accordion-header-div">
            <div class="accordion-caret"></div>
          </div>
        </a>
        <div class="divider-line">
          <hr />
        </div>
      </div>
      <div class="accordion-body" :ref="'accordion-body-' + i">
        <div class="accordion-content">
          <div v-if="content.title === 'Prezzo'">
            <div class="price-container">
              <div class="price-left">{{ rangeSlider[0] }}€</div>
              <div class="price-right">{{ rangeSlider[1] }}€</div>
            </div>
            <vue-range-slider
              v-model="rangeSlider"
              :max="maxRange"
              tooltip="false"
              :height="2"
              :real-time="true"
              :process-style="{ backgroundColor: 'red' }"
              :enable-cross="false"
            ></vue-range-slider>
          </div>
          <div v-else>
            <div v-for="(category, i) in content.categories" :key="i">
              <md-checkbox v-model="category.active">{{
                category.name
              }}</md-checkbox>
            </div>
            {{ content.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/scss/accordion.scss";
import { gsap, Elastic, Bounce } from "gsap/all";
import "vue-range-component/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component";

gsap.registerPlugin(Elastic, Bounce);

export default {
  data() {
    return {
      rangeSlider: [],
      maxRange: 0,
      contents: [
        {
          title: "Prezzo",
          categories: [],
          active: false,
        },
        {
          title: "Categoria",
          categories: [
            { name: "Computer", active: true },
            { name: "Accessorio", active: true },
            { name: "Collezione", active: true },
          ],
          active: false,
        },
      ],
    };
  },
  props: {
    maxValue: Number,
  },
  computed: {
    filters() {
      return {
        price: this.rangeSlider,
        categories:
          typeof this.contents[1] !== "undefined"
            ? this.contents[1].categories
            : [],
      };
    },
  },
  emits: ["change"],
  watch: {
    filters(newValue) {
      this.$emit("change", newValue);
    },
    maxValue(newValue) {
      this.rangeSlider = [0, newValue];
      this.maxRange = newValue;
    },
  },
  components: {
    VueRangeSlider,
  },
  methods: {
    expand(e, i) {
      e.preventDefault();
      let el = this.$refs["accordion-body-" + i][0];

      if (this.contents[i].active === false) {
        this.contents[i].active = true;

        gsap.to(el, 1, {
          height: el.scrollHeight,
          ease: Elastic.easeOut.config(1, 0.3),
        });
      } else {
        this.contents[i].active = false;

        gsap.to(el, 0.5, {
          height: 0,
          ease: Bounce.easeOut,
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-accordion-header {
  color: black;
}

.custom-accordion-header:hover {
  color: red;
}

.divider-line {
  font-size: 4px;
  margin-left: 10px;
  margin-right: 10px;
}

hr {
  border: none;
  height: 1px;
  color: rgb(211, 211, 211);
  background-color: rgb(211, 211, 211);
}

.price-container {
  width: 100%;
  padding-bottom: 25px;
}

.price-left {
  float: left;
  margin: 4px;
  font-weight: bold;
  display: inline;
}

.price-right {
  float: right;
  margin: 4px;
  font-weight: bold;
  display: inline;
}
</style>