<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
    </parallax>
    <div class="main main-raised small-devices-margin large-devices-margin">
      <notifications group="foo" position="bottom right" />
      <div class="section" style="margin-bottom: 30%">
        <div class="container">
          <modal
            v-if="modal"
            @close="modal = false"
            style="max-height 40%; margin-top: -5rem"
          >
            <template slot="header">
              <h4 class="modal-title">Modifica Prodotto</h4>
              <md-button
                class="md-simple md-just-icon md-round modal-default-button"
                @click="modal = false"
              >
                <md-icon>clear</md-icon>
              </md-button>
            </template>

            <template slot="body">
              <div>
                <md-field class="has-info">
                  <label>Titolo</label>
                  <md-input v-model="currentProduct.title" required></md-input>
                </md-field>
                <md-field class="has-info" style="margin-top: 20px">
                  <label>Descrizione</label>
                  <md-textarea
                    required
                    class="has-info"
                    v-model="currentProduct.description"
                  ></md-textarea>
                </md-field>
                <md-field style="margin-top: 20px">
                  <label for="movie">Categoria</label>
                  <md-select
                    required
                    v-model="currentProduct.category"
                    name="category"
                    id="category"
                  >
                    <md-option value="Computer">Computer</md-option>
                    <md-option value="Accessorio">Accessorio</md-option>
                    <md-option value="Modellino">Collezione</md-option>
                    <md-option value="Ricambio">Ricambio</md-option>
                  </md-select>
                </md-field>
                <md-field style="margin-top: 20px">
                  <label for="movie">Azienda</label>
                  <md-select
                    required
                    v-model="currentProduct.company"
                    name="category"
                    id="category"
                  >
                    <md-option value="Trivellato SpA">Trivellato SpA</md-option>
                    <md-option value="Trivellato Industriali"
                      >Trivellato Industriali</md-option
                    >
                  </md-select>
                </md-field>
                <md-field class="has-info" style="margin-top: 20px">
                  <label>Prezzo</label>
                  <md-input v-model="currentProduct.price" required></md-input>
                </md-field>
                <md-field class="has-info" style="margin-top: 20px">
                  <label>Prezzo in Offerta</label>
                  <md-input
                    v-model="currentProduct.offerPrice"
                    required
                  ></md-input>
                </md-field>
                <md-field class="has-info" style="margin-top: 20px">
                  <label>Quantità</label>
                  <md-input
                    required
                    type="number"
                    v-model="currentProduct.maxQuantity"
                  ></md-input>
                </md-field>
                <md-field style="margin-top: 20px">
                  <label for="movie">Status</label>
                  <md-select
                    required
                    v-model="currentProduct.status"
                    name="status"
                    id="status"
                  >
                    <md-option value="IN STOCK">IN STOCK</md-option>
                    <md-option value="OUT OF STOCK">OUT OF STOCK</md-option>
                  </md-select>
                </md-field>
                <md-field class="has-info" style="margin-top: 20px">
                  <label>Numero Cespite</label>
                  <md-input
                    v-model="currentProduct.numeroCespite"
                    required
                  ></md-input>
                </md-field>
                <VueFileAgent
                  ref="vueFileAgent"
                  :multiple="true"
                  :accept="'image/*'"
                  :deletable="true"
                  :maxSize="'900KB'"
                  :errorText="{
                    type: 'Formato File Invalido',
                    size: 'Il file non deve superare i 900Kb',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </div>
            </template>

            <template slot="footer">
              <md-button class="md-danger md-simple" @click="modal = false"
                >Chiudi</md-button
              >
              <md-button class="md-success md-simple" @click="saveProduct">{{
                loading ? "Carcamento..." : "Salva"
              }}</md-button>
            </template>
          </modal>
          <div style="margin: 20px">
            <div style="display: inline">
              <div class="title" style="display: inline">
                <h2 style="display: inline">Gestisci prodotti</h2>
              </div>
              <md-button
                @click="openModalNew"
                style="display: inline; margin-left: 40%; marign-right: 40%"
                class="md-button md-just-icon md-round md-simple md-theme-default"
                ><i class="fas fa-plus" style="font-size: 30px"></i
              ></md-button>
            </div>

            <div>
              <div style="margin-top: 30px">
                <md-field class="has-info">
                  <label
                    >Cerca (prodotto, prezzo, numero Cespite, status)..</label
                  >
                  <md-input v-model="input"></md-input>
                </md-field>
              </div>
              <div style="margin-top: 70px">
                <md-table>
                  <md-table-row style="background: unset !important">
                    <md-table-head></md-table-head>
                    <md-table-head>Prodotto</md-table-head>
                    <md-table-head>Prezzo</md-table-head>
                    <md-table-head>Quantità</md-table-head>
                    <md-table-head>Status</md-table-head>
                    <md-table-head></md-table-head>
                  </md-table-row>
                  <md-table-row
                    v-for="(product, index) in filteredProducts"
                    :key="index"
                    style="background: unset !important"
                  >
                    <md-table-cell
                      ><div
                        class="img-container"
                        style="max-width: 170px; min-width: 110px"
                      >
                        <img :src="product.productImages[0]" /></div
                    ></md-table-cell>
                    <md-table-cell style="color: purple; font-size: 17px"
                      ><b>{{ product.title }}</b></md-table-cell
                    >
                    <md-table-cell>€{{ product.price }}</md-table-cell>
                    <md-table-cell>{{ product.maxQuantity }}</md-table-cell>
                    <md-table-cell>{{ product.status }}</md-table-cell>
                    <md-table-cell
                      ><md-button
                        @click="openModal(index)"
                        class="md-button md-just-icon md-round md-simple md-theme-default"
                        ><i class="md-icon md-icon-font md-theme-default"
                          >edit</i
                        ></md-button
                      ></md-table-cell
                    >
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../views/components/StoreState.js";
import User from "../views/components/UserState";
import route from "../router";
import fetchFromAPI from "../scripts/Fetch";
import { Modal } from "@/components";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";

export default {
  data() {
    return {
      input: "",
      products: [],
      modal: false,
      currentProduct: {},
      notification: false,
      notifyMessage: "",
      fileRecordsForUpload: [],
      fileRecords: [],
      nuovo: false,
      loading: false,
    };
  },
  props: {
    image: {
      type: String,
      default: require("../assets/img/details-background.jpg"),
    },
  },
  components: {
    Modal,
  },
  methods: {
    openModal(index) {
      this.currentProduct = this.filteredProducts[index];
      this.modal = true;
      this.nuovo = false;
    },
    openModalNew() {
      this.currentProduct = {};
      this.modal = true;
      this.nuovo = true;
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        if (confirm("Sicuro di voler eliminare l'immagine?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      }
    },
    async fileListToBase64(fileList) {
      function getBase64(file) {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (ev) => {
            resolve(ev.target.result);
          };
          reader.readAsDataURL(file);
        });
      }
      const promises = [];
      for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i].file));
      }
      return await Promise.all(promises);
    },
    async saveProduct() {
      this.loading = true;
      this.currentProduct.price = this.currentProduct.price
        .toString()
        .replace(",", ".");
      this.currentProduct.offerPrice = this.currentProduct.offerPrice ? this.currentProduct.offerPrice.toString().replace(",", ".") : "0.0";
      if (this.fileRecordsForUpload.length > 0) {
        const reader = new FileReader();
        var promise = Promise.resolve();
        let records = await this.fileListToBase64(this.fileRecordsForUpload);
        this.currentProduct.productImages = records;
      }

      let endpoint = this.nuovo ? "/createItem" : "/saveItem";

      fetchFromAPI(
        endpoint,
        { product: this.currentProduct, token: User.getToken() },
        (res) => {
          if (res) {
            this.$notify({
              group: "foo",
              title: "Successo",
              type: "success",
              text: "Prodotto salvato con successo",
            });
            this.modal = false;
          } else {
            this.$notify({
              group: "foo",
              title: "Errore",
              type: "error",
              text: "Si è verificato un errore nel salvataggio del prodotto",
            });
            this.modal = false;
          }
          this.loading = false;
        }
      );
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        maxHeight: "370px",
      };
    },
    filteredProducts() {
      let products = [];
      this.products.forEach((p) => {
        if (
          p.title.toLowerCase().includes(this.input.toLowerCase()) ||
          p.status.toLowerCase().includes(this.input.toLowerCase()) ||
          p.numeroCespite.toLowerCase().includes(this.input.toLowerCase()) ||
          p.price.toString().includes(this.input)
        ) {
          products.push(p);
        }
      });
      return products;
    },
  },
  created() {
    fetchFromAPI("/loadAll", {}, (res) => {
      this.products = res.products;
    });
  },
};
</script>

<style scoped>
@media all and (max-width: 1400px) and (min-width: 200px) {
  .small-devices-margin {
    margin-right: 5%;
    margin-left: 5%;
  }

  .small-devices-image {
    min-width: 105px;
    margin-left: 13%;
    margin-right: 13%;
  }
}

@media all and (max-width: 6000px) and (min-width: 1400px) {
  .large-devices-margin {
    margin-right: 13%;
    margin-left: 13%;
  }

  .large-devices-image {
    min-width: 150px;
  }
}
</style>